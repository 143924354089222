<template>
  <div>
    <el-button type="text" class="btn" icon="el-icon-download" @click="fileDownLoad(message,chartsName,dateTime)">导出Excel</el-button>
  </div>
</template>

<script>
  export default {
    
    name:'',
    props: {
        message: [],
        chartsName: String,
        dateTime: Object,
    },
    data () {
      return {
        fileData: [],
        name: "",
        ExcelName: "",
        date: null,
        startDate: "",
        endDate: ""
        // seriesData: []
      };
    },
    created(){
    },
    components: {},

    computed: {},

    beforeMount() {},

    mounted() {},

    methods: {
        fileDownLoad(message,chartsName,dateTime){
            this.fileData=[];
            this.name = "";
            this.ExcelName = "";
            this.date = "";
            this.startDate = "";
            this.endDate = "";
            // console.log(1)
            // console.log(message)
            // console.log(chartsName)
            this.date = dateTime;
            // console.log(this.date)
            this.startDate = JSON.stringify(this.date.startTime).split('"')[1].split('T')[0];
            // console.log(this.startDate)
            this.endDate = JSON.stringify(this.date.endTime).split('"')[1].split('T')[0];
            // console.log(this.endDate)
            if(message && message.length > 0){
                // this.startTime = dateTime.startTime
                message.forEach(item => {
                    let data=[item.name,item.value];
                    this.fileData.push(data);
                });
                // console.log(this.fileData);
                let xAxisData = [];
                if(chartsName=="商品出售数量占比饼状图"){
                    xAxisData = ['姓名', '数量(件)'];
                } else{
                    xAxisData = ['姓名', '金额(元)'];
                }
                this.ExcelName = chartsName.split("饼")[0] + "表";
                // console.log(this.ExcelName)
                this.name = this.ExcelName + "_" + this.startDate + "_" + this.endDate;
                // console.log(name)
                import('@/vendor/Export2Excel').then((excel) => {
                    // 导入成功后执行相关代码
                    excel.export_json_to_excel({
                        header: xAxisData, // 表头信息
                        data: this.fileData, // 要保存到 Excel 表中的数据
                        filename: this.name, // 文件名
                        autoWidth: true, // 是否自动调整列宽
                        bookType: 'xlsx', // 文件类型
                    })
                })
            }
        },
    },

    watch: {}

  }

</script>
<style lang='scss' scoped>
.btn{
    font-size:13px;
    margin-right:20px;
    padding:0;
    vertical-align:middle;
}
</style>