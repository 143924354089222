<template>
  <div>
    <div style="text-align: right; margin-bottom: 10px; overflow: hidden">
      <h2 style="float: left">合同签订款项折线图</h2>
      <el-date-picker
        style="margin-right: 20px"
        size="mini"
        format="yyyy-MM-dd HH:mm:ss"
        v-model="timer"
        type="datetimerange"
        align="right"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        @change="mothRangeChange"
      >
      </el-date-picker>
    </div>

    <div
      v-loading="loading"
      :id="id"
      :class="className"
      :style="{ height: height, width: width }"
    ></div>
    <div>总销售金额:¥ {{ saleAllPrice }}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import resize from '../../../MyReport/Purchase/chart/mixins/resize'
import { reportInfo } from '@/api/contract'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  created() {},
  async mounted() {
    var now = new Date() //当前日期
    var nowMonth = now.getMonth() //当前月
    var nowYear = now.getFullYear() //当前年
    //本月的开始时间
    var timeStar = new Date(nowYear, nowMonth, 1)
    //本月的结束时间
    var timeEnd = new Date(nowYear, nowMonth + 1, 0, 23, 59, 59)

    this.timer = [timeStar, timeEnd]

    this.getReport()
  },
  data() {
    return {
      loading: false,
      chart: null,
      data: null,
      timer: '',
      userId: null,
      userOption: [],
      timeFilterType: null,
      timerFilterTypeOptions: [],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }

      this.chart = echarts.init(document.getElementById(this.id))

      let legendData = this.data.map((item) => {
        return '合同售卖总值:'
      })

      let dateArr = this.data.map((item) => new Date(item.auditTime))

      console.log("legendData",legendData)

      let maxDate = new Date(Math.max.apply(null, dateArr))
      let minDate = new Date(Math.min.apply(null, dateArr))

      console.log(maxDate, minDate)

      let dateArange = []
      let oneDay = 24 * 3600 * 1000

      for (let i = +minDate; i <= +maxDate; i += oneDay) {
        let time = new Date(i)
        dateArange.push(
          new Date(time.getFullYear(), time.getMonth(), time.getDate())
        )
      }

      console.log('dateArange', dateArange)

      let seriesData = dateArange.map((date) => {
        let sTime = +date

        let eTime = sTime + oneDay

        if (
          this.data.some((u) => {
            var timeSpan = +u.auditTime
            return sTime <= timeSpan < eTime
          })
        ) {
          return this.data
            .filter((u) => {
              var timeSpan = +new Date(u.auditTime)
              return sTime <= timeSpan < eTime
            })
            .reduce((pre, cur) => {
              pre += cur.price

              return pre
            }, 0)
        } else {
          return 0
        }
      })

      let xAxisData = dateArange.map((item) => {
        return this.$options.filters['timeFormat'](item, 'yyyy-MM-dd')
      })


      let option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: legendData,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [{ data: seriesData, type: 'line' }],
      }

      option && this.chart.setOption(option)
    },
    async getReport() {
      this.loading = true
      let timerFilter = {}

      if (this.timer) {
        timerFilter.startTime = this.timer[0]
        timerFilter.endTime = this.timer[1]
      }

      let { data } = await reportInfo(timerFilter)

      this.data = data

      this.initChart()
      this.loading = false
    },
    mothRangeChange(timer) {
      this.getReport()
    },
    arrSum(arr) {
      let sum = 0
      arr.forEach((item) => {
        if (item != null) {
          sum += item
        }
      })
      return sum
    },
  },
  computed: {
    saleAllPrice() {
      let sumPrice = 0
      if (!this.data) {
        return sumPrice
      }

      sumPrice = this.data.reduce((pre, cur) => {
        pre += cur.price
        return pre
      }, 0)

      return sumPrice.toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped></style>
