<template>
  <div>
    <div style="text-align: right; margin-bottom: 10px">
      <span style="float: left; font-weight: bold">月度消费折线图</span>
      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="timeFilterType"
        @change="timerFilterTypeSelectChange"
      >
        <el-option
          v-for="item in timerFilterTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="timerFilterYear"
        @change="getReport"
      >
        <el-option
          v-for="item in timerFilterYearOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div
      v-loading="loading"
      :id="id"
      :class="className"
      :style="{ height: height, width: width }"
    ></div>
    <div>总消费金额: {{ saleAllPrice }} 元</div>
  </div>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import * as echarts from 'echarts'
import resize from './mixins/resize'
import {
  getProductTimeSendNumber,
  getTimeFilterOptions,
  getMonthOrder,
} from '@/api/outStorage.js'
import { getFilterProduct } from '@/api/product.js'
import { start } from 'nprogress'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  created() {},
  async mounted() {
    let { data } = await getTimeFilterOptions()

    this.timeFilterType = data[0].value
    this.timerFilterTypeOptions = data

    let year = new Date().getFullYear()

    // this.timerFilterYearOptions = [
    //   {
    //     label: year + '',
    //     value: year,
    //   },
    //   {
    //     label: year - 1 + '',
    //     value: year - 1,
    //   },
    //   {
    //     label: year - 2 + '',
    //     value: year - 2,
    //   },
    //   {
    //     label: year - 3 + '',
    //     value: year - 3,
    //   },
    // ]

    this.timerFilterYearOptions = [
      {
        label: '全部',
        value: '',
      },
    ]

    for (let index = 0; index < 10; index++) {
      this.timerFilterYearOptions.push({
        label: year + '',
        value: year,
      })
      year--
    }

    this.getReport()
  },
  data() {
    return {
      loading: false,
      chart: null,
      data: null,
      timer: '',
      productId: null,
      productOption: [],
      timeFilterType: null,
      timerFilterTypeOptions: [],
      timerFilterYear: '2024',
      timerFilterYearOptions: [],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    timerFilterTypeSelectChange(val) {
      this.timeFilterType = val
      this.getReport()
    },
    initChart() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }

      this.chart = echarts.init(document.getElementById(this.id))

      let tableData = this.data.reverse()

      let date = tableData.reduce((pre, cur) => {
        let arr = cur.data.map((u) => `${cur.name}年-${u.month}月`)
        return [...pre, ...arr]
      }, [])

      let data = tableData.reduce((pre, cur) => {
        let arr = cur.data.map((u) => u.money)
        return [...pre, ...arr]
      }, [])

      let option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%']
          },
        },
        title: {
          text: '',
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: date,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            name: '消费金额',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgb(84, 112, 198)',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#E7EFFF',
                },
                {
                  offset: 1,
                  color: '#E7EFFF',
                },
              ]),
            },
            data: data,
          },
        ],
      }

      option && this.chart.setOption(option)
    },
    async getReport() {
      this.loading = true
      let timerFilter = {}

      let arr = []

      if (this.timeFilterType) {
        timerFilter.timeFilterType = this.timeFilterType
      }

      if (this.timerFilterYear == '') {
        for (
          let index = 1;
          index < this.timerFilterYearOptions.length;
          index++
        ) {
          timerFilter.year = this.timerFilterYearOptions[index].value
          let { data } = await getMonthOrder(timerFilter)
          arr.push({ name: this.timerFilterYearOptions[index].label, data })
        }
      } else {
        timerFilter.year = this.timerFilterYear
        let { data } = await getMonthOrder(timerFilter)
        arr.push({ name: this.timerFilterYear, data })
      }

      console.log(arr)

      this.data = arr

      this.initChart()
      this.loading = false
    },
    mothRangeChange(timer) {
      this.getReport()
    },
  },
  computed: {
    saleAllPrice() {
      console.log(JSON.stringify(this.data))

      try {
        return this.data
          .reduce((prev, curr) => {
            prev += curr.data.reduce((pi, ci) => {
              pi += ci.money
              return pi
            }, 0)
            return prev
          }, 0)
          .toFixed(2)
      } catch (error) {
        return 0
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
