<template>
  <div>
    <div style="text-align: right; margin-bottom: 10px">
      <span style="float: left; font-weight: bold">年度消费折线图</span>
      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="timeFilterType"
        @change="timerFilterTypeSelectChange"
      >
        <el-option
          v-for="item in timerFilterTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="timerFilterYear"
        @change="getReport"
      >
        <el-option
          v-for="item in timerFilterYearOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div
      v-loading="loading"
      :id="id"
      :class="className"
      :style="{ height: height, width: width }"
    ></div>
    <div>总消费金额: {{ saleAllPrice }} 元</div>
  </div>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import * as echarts from 'echarts'
import resize from './mixins/resize'
import {
  getProductTimeSendNumber,
  getTimeFilterOptions,
  getMonthOrder,
} from '@/api/outStorage.js'
import { getFilterProduct } from '@/api/product.js'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  created() {},
  async mounted() {
    let { data } = await getTimeFilterOptions()

    this.timeFilterType = data[0].value
    this.timerFilterTypeOptions = data

    let year = new Date().getFullYear()

    // this.timerFilterYearOptions = [
    //   {
    //     label: year + '',
    //     value: year,
    //   },
    //   {
    //     label: year - 1 + '',
    //     value: year - 1,
    //   },
    //   {
    //     label: year - 2 + '',
    //     value: year - 2,
    //   },
    //   {
    //     label: year - 3 + '',
    //     value: year - 3,
    //   },
    // ]

    this.timerFilterYearOptions = [
      {
        label: '全部',
        value: '',
      },
    ]

    for (let index = 0; index < 10; index++) {
      this.timerFilterYearOptions.push({
        label: year + '',
        value: year,
      })
      year--
    }

    this.getReport()
  },
  data() {
    return {
      loading: false,
      chart: null,
      data: null,
      timer: '',
      productId: null,
      productOption: [],
      timeFilterType: null,
      timerFilterTypeOptions: [],
      timerFilterYear: '',
      timerFilterYearOptions: [],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    timerFilterTypeSelectChange(val) {
      this.timeFilterType = val
      this.getReport()
    },
    initChart() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }

      this.chart = echarts.init(document.getElementById(this.id))

      let legendData = this.data.map((item) => {
        return {
          emphasis: {
            focus: 'series',
          },
          name: item.name,
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#4D4D4D',
              fontSize: 12,
            },
            formatter(params) {
              return '¥' + params.value
            },
          },
          type: 'line',
          // stack: 'Total',
          connectNulls: true,
          data: item.data.map((i) => {
            return i.money
          }),
        }
      })

      let xAxisData = [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
      ]

      let option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: legendData.map((item) => item.name),
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: legendData,
      }

      option && this.chart.setOption(option)
    },
    async getReport() {
      this.loading = true
      let timerFilter = {}

      let arr = []

      if (this.timeFilterType) {
        timerFilter.timeFilterType = this.timeFilterType
      }

      if (this.timerFilterYear == '') {
        for (
          let index = 1;
          index < this.timerFilterYearOptions.length;
          index++
        ) {
          timerFilter.year = this.timerFilterYearOptions[index].value
          let { data } = await getMonthOrder(timerFilter)
          arr.push({ name: this.timerFilterYearOptions[index].label, data })
        }
      } else {
        timerFilter.year = this.timerFilterYear
        let { data } = await getMonthOrder(timerFilter)
        arr.push({ name: this.timerFilterYear, data })
      }

      console.log(arr)

      this.data = arr

      this.initChart()
      this.loading = false
    },
    mothRangeChange(timer) {
      this.getReport()
    },
  },
  computed: {
    saleAllPrice() {
      console.log(JSON.stringify(this.data))

      try {
        return this.data
          .reduce((prev, curr) => {
            prev += curr.data.reduce((pi, ci) => {
              pi += ci.money
              return pi
            }, 0)
            return prev
          }, 0)
          .toFixed(2)
      } catch (error) {
        return 0
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
