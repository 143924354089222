<template>
  <div>
    <div style="text-align: right; margin-bottom: 10px">
      <downLoad style="display:inline-block" :message="this.fileMessage" :chartsName="fileName" :dateTime="fileDateTime"></downLoad>
      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="timeFilterType"
        @change="timerFilterTypeSelectChange"
      >
        <el-option
          v-for="item in timerFilterTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="productType"
        @change="productTypeSelectChange"
      >
        <el-option
          v-for="item in productTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="userId"
        clearable
        filterable
        @focus="onFocus"
        remote
        placeholder="请输入用户名"
        :remote-method="userRemoteFun"
        @change="userIdSelectChange"
      >
        <el-option
          v-for="item in userOption"
          :key="item.id"
          :label="item.userName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        style="margin-right: 20px"
        size="mini"
        v-model="timer"
        type="datetimerange"
        align="right"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        @change="mothRangeChange"
      >
      </el-date-picker>
      <!-- <el-button type="success" size="mini" @click="exportExcel"
        >导出Excel</el-button
      > -->
    </div>

    <div
      v-loading="loading"
      :id="id"
      :class="className"
      :style="{ height: height, width: width }"
    ></div>
    <div>总销售商品数量: {{ saleAllPrice }} 件</div>
  </div>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import * as echarts from 'echarts'
import resize from './mixins/resize'
import {
  getReporProductNumber,
  getTimeFilterOptions,
  getProductTypeOptions,
} from '@/api/outStorage.js'
import { getUserOptions } from '@/api/user.js'
import downLoad from '@/components/Charts/downLoad.vue'
import { iosOnFocus } from '@/utils/iosPlatform.js'
export default {
  mixins: [resize],
  components: {
    'downLoad': downLoad
  },
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  created() {},
  async mounted() {
    var now = new Date() //当前日期
    var nowMonth = now.getMonth() //当前月
    var nowYear = now.getFullYear() //当前年
    //本月的开始时间
    var timeStar = new Date(nowYear, nowMonth, 1)
    //本月的结束时间
    var timeEnd = new Date(nowYear, nowMonth + 1, 0, 23, 59, 59)

    this.timer = [timeStar, timeEnd]

    let { data } = await getTimeFilterOptions()

    this.timeFilterType = data[0].value
    this.timerFilterTypeOptions = data

    let { data: productType } = await getProductTypeOptions()

    this.productType = productType[0].value
    this.productTypeOptions = productType

    this.getReport()
  },
  data() {
    return {
      loading: false,
      chart: null,
      data: null,
      timer: '',
      userId: null,
      userOption: [],
      timeFilterType: null,
      timerFilterTypeOptions: [],
      productType: null,
      productTypeOptions: [],
      fileMessage: [],   //传递给子组件的数据
      fileName: '',   //传递给子组件的文件名
      fileDateTime : null   //传递给子组件的文件名的时间段
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    // 用户远程搜索
    async userRemoteFun(key) {
      if (key !== '') {
        let { data } = await getUserOptions(key)
        this.userOption = data
      }
    },
    // 用户名选中更改
    async userIdSelectChange(dataId) {
      this.userId = dataId
      this.getReport()
    },
    timerFilterTypeSelectChange(val) {
      this.timeFilterType = val
      this.getReport()
    },
    productTypeSelectChange(val) {
      this.productType = val
      this.getReport()
    },
    initChart() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }

      this.chart = echarts.init(document.getElementById(this.id))

      let option = {
        title: {
          text: '商品出售数量占比饼状图',
          subtext: '',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} :  {c} 件({d}%)',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          left: 10,
          top: 20,
          bottom: 20,
        },
        series: [
          {
            name: '商品销售数量',
            type: 'pie',
            radius: '50%',
            data: this.data ?? [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      this.fileName = option.title.text;
      option && this.chart.setOption(option)
    },
    async getReport() {
      this.loading = true
      let timerFilter = {}

      if (this.userId) {
        timerFilter.userId = this.userId
      }

      if (this.timeFilterType) {
        timerFilter.timeFilterType = this.timeFilterType
      }

      if (this.productType) {
        timerFilter.productType = this.productType
      }

      if (this.timer) {
        timerFilter.startTime = timeOffset(this.timer[0])
        timerFilter.endTime = timeOffset(this.timer[1])
      }

      console.log(this.timer)

      this.fileDateTime = timerFilter;
      let { data } = await getReporProductNumber(timerFilter)

      this.data = data
      this.fileMessage = data;
      this.initChart()

      this.loading = false
    },
    mothRangeChange(timer) {
      this.getReport()
    },
    // 导出当前报表的Excel数据
    exportExcel() {
      this.$message.info('当前表格中不需要具体数据')
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
  computed: {
    saleAllPrice() {
      let sumPrice = 0
      if (!this.data) {
        return sumPrice
      }

      this.data.forEach((item) => {
        sumPrice += item.value
      })

      return sumPrice.toFixed(0)
    },
  },
}
</script>

<style lang="scss" scoped></style>
