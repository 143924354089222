<template>
  <div>
    <el-row style="margin-bottom: 20px" :gutter="20">
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <UserSalePieChart
          class="echart_card"
          :id="'userSalePieChart'"
          height="500px"
          width="100%"
        />
      </el-col>
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <ProductSalePieChart
          class="echart_card"
          :id="'productSalePieChart'"
          height="500px"
          width="100%"
        />
      </el-col>
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <ProductSaleNumberPieChart
          class="echart_card"
          :id="'ProductSaleNumberPieChart'"
          height="500px"
          width="100%"
        />
      </el-col>
      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <UserDateTimeLineChart
          class="echart_card"
          :id="'userDateTimeLineChart'"
          height="800px"
          width="100%"
        />
      </el-col>

      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <ProductTimeSendLineChart
          class="echart_card"
          :id="'productTimeSendLineChart'"
          height="800px"
          width="100%"
        />
      </el-col>

      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <contractLineChart
          class="echart_card"
          :id="'contractLineChart'"
          height="500px"
          width="100%"
        />
      </el-col>

      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <MonthOrderLineChart
          class="echart_card"
          :id="'ponthOrderLineChart'"
          height="800px"
          width="100%"
        />
      </el-col>

      <el-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 24 }"
        :xl="{ span: 24 }"
        style="padding-right: 8px; margin-bottom: 30px"
      >
        <MonthOrderOneLineChart
          class="echart_card"
          :id="'monthOrderLineChart'"
          height="800px"
          width="100%"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserSalePieChart from './charts/UserSalePieChart.vue'
import ProductSalePieChart from './charts/ProductSalePieChart.vue'
import UserDateTimeLineChart from './charts/UserDateTimeLineChart.vue'
import ProductSaleNumberPieChart from './charts/ProductSaleNumberPieChart.vue'
import ProductTimeSendLineChart from './charts/ProductTimeSendLineChart.vue'
import contractLineChart from './charts/contractLineChart.vue'
import MonthOrderLineChart from './charts/MonthOrderLineChart.vue'
import MonthOrderOneLineChart from './charts/MonthOrderOneLineChart.vue'

import { getReportUserSale } from '@/api/outStorage.js'
export default {
  components: {
    UserSalePieChart,
    ProductSalePieChart,
    UserDateTimeLineChart,
    ProductSaleNumberPieChart,
    ProductTimeSendLineChart,
    MonthOrderLineChart,
    MonthOrderOneLineChart,
    contractLineChart,
  },
  data() {
    return {}
  },
  created() {
    this.getUserReport()
  },
  methods: {
    async getUserReport() {
      let { data } = await getReportUserSale()

      console.log(data)
    },
  },
}
</script>

<style lang="scss" scoped>
.echart_card {
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
